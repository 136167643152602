// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, writeBatch } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// import { setDoc } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
   apiKey: "AIzaSyAndDPCwL7dMVlsWFMrBtlYdkiY_VW7x2s",
  authDomain: "eventquestions-459a0.firebaseapp.com",
  projectId: "eventquestions-459a0",
  storageBucket: "eventquestions-459a0.appspot.com",
  messagingSenderId: "347513817801",
  appId: "1:347513817801:web:65e618bfdad1f587b99b18",
  measurementId: "G-PSKSWZX6PR"
  // apiKey: "AIzaSyD8EKVPyWa9lp_6ho_isk1s8dzV3pfrE3c",
  // authDomain: "eventquestionsv2.firebaseapp.com",
  // projectId: "eventquestionsv2",
  // storageBucket: "eventquestionsv2.appspot.com",
  // messagingSenderId: "981987305222",
  // appId: "1:981987305222:web:a0f287fde5251ce25f6087"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const admin= firebase.firestore();
const auth=getAuth(app);
// const set =setDoc(app)



export { db, storage,auth }