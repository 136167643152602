import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import BlogPosts from "./views/BlogPosts";
import AddQuestions from "./views/AddQuestions";
import EditQuestions from "./views/EditQuestions";
import ActivateQuestion from "./views/ActivateQuestion";
import ViewQuestions from "./views/ViewQuestions";
import Report from "./views/Report";
import Report2 from "./views/Report2"
import Login from "./views/Login"
import DragableQuestions from "./views/DragableQuestions";
import UserDetail from "./views/UserDetail";
import AvatarTables from "./views/AvatarTables";

export default [

  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login-user" />
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/usersInfo",
    layout: DefaultLayout,
    component: UserDetail
  },
  {
    path: "/login-user",
    layout: Login,
    component: Login
  },
  {
    path: "/dragablequestions",
    layout: DefaultLayout,
    component: DragableQuestions
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/editQuestions/:id",
    layout: DefaultLayout,
    component: EditQuestions
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/AddQuestions",
    layout: DefaultLayout,
    component: AddQuestions
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/avatarInfo",
    layout: DefaultLayout,
    component: AvatarTables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },{
    path:"/activequestion",
    layout: DefaultLayout,
     component:ActivateQuestion,
  },
  {
    path:"/viewquestions",
    layout:DefaultLayout,
    component:ViewQuestions,
  },
  {
    path:"/viewreport/:eid/:id",
    layout:DefaultLayout,
    component:Report,
  },
  {
    path:"/copyQuestions",
    layout:DefaultLayout,
    component:Report2,
  }
];
