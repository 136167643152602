import React from "react";
import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CardBody,
  FormInput,
  FormGroup,
  Button,
} from "shards-react";
import { db, storage, set } from "../firebase";
import PageTitle from "../components/common/PageTitle";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import {
  addDoc,
  doc,
  getDoc,
  updateDoc,
  collection,
  setDoc,
  serverTimestamp
} from "firebase/firestore";
// import EmailIcon from '@mui/icons-material/Email';

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loader, setLoader] = useState(null);
  const [registerPassword, setRegisterPassword] = useState("");
  const [eventId, setEventId] = useState("");
  const history = useHistory();
  const loginS = async (e) => {
    e.preventDefault();
    try {
      setLoader("pending");
      await signInWithEmailAndPassword(auth, loginEmail, registerPassword);
      console.log("login successfull");

      const docRef = doc(db, "metaData", "2YQlRZL0iRSSvd6rFrXn");

      await updateDoc(docRef, {
        eventID: eventId,
      });
      await setDoc(doc(db, "events", eventId), {
        // eventids:eventId,
        currentQuestion: "",
        didEventExpire: false,
        timer: 10,
        timestamp: serverTimestamp(),
      });
      await setDoc(doc(db, "icrement", eventId), {
        i: 1,
      });

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data().eventID);
        localStorage.setItem("eventID", docSnap.data().eventID);
      } else {
        console.log("No such document!");
      }
      NotificationManager.success("Signin Successfully");
      setLoader(null);
      history.push("/dragablequestions");
    } catch (error) {
      console.log(error);
      NotificationManager.error("Password/Email Incorrect");
      setLoader(null);
    }
  };

  return (
    <Container
      style={{ height: "100vh" }}
      className={"px-4 pb-4 d-flex align-items-center  justify-content-center"}
    >
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="" className="text-sm-left" />
      </Row>
      <div className="">
        <Card style={{ width: "450px" }}>
          <CardBody>
            <div className="text-center">
              <CardHeader>
                <h3>Login</h3>
              </CardHeader>
            </div>
            <Form onSubmit={loginS}>
              <FormGroup>
                <label htmlFor="Email">Email</label>
                <FormInput
                  size="lg"
                  required
                  disabled={loader === "pending"}
                  type="text"
                  value={loginEmail}
                  onChange={(event) => {
                    setLoginEmail(event.target.value);
                  }}
                  id="#question"
                  placeholder="Email Address"
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="password">Password</label>
                <FormInput
                  size="lg"
                  required
                  disabled={loader === "pending"}
                  type="password"
                  value={registerPassword}
                  onChange={(event) => {
                    setRegisterPassword(event.target.value);
                  }}
                  id="#question"
                  placeholder="Password"
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="password">Event ID</label>
                <FormInput
                  size="lg"
                  required
                  type="number"
                  disabled={loader === "pending"}
                  min="0"
                  value={eventId}
                  onChange={(event) => {
                    setEventId(event.target.value);
                  }}
                  id="#eventId"
                  placeholder=" Event ID"
                />
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  disabled={loader === "pending"}
                  color="primary"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  {loader === "pending" ? (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <strong style={{ fontSize: "17px" }}>Sign in</strong>
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <NotificationContainer />
    </Container>
  );
};

export default Login;
