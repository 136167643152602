import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardHeader,
  CardBody,
  FormRadio,
  FormInput,
  FormGroup,
  Button,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { db, storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { useHistory, Link } from "react-router-dom";
import { async } from "@firebase/util";

export default function AddQuestions() {
  const [questions, setQuestions] = useState([]);
  const [imageUrls, setImageUrls] = useState(null);
  const [videoUrls, setVideoUrls] = useState(null);
  const [type, setType] = useState("text");
  const [question, setQuestion] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [timer, setTimer] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(null);
  const history = useHistory();

  useEffect(() => {
    console.log(localStorage.getItem("eventID"));
    const fetch = async () => {
      try {
        const a = await tempFunc();
        setQuestions(a);
        console.log(a.length);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, []);

  async function tempFunc() {
    const questionsRef = collection(db, "questions");
    const q = query(
      questionsRef,
      where("eventID", "==", localStorage.getItem("eventID")),
      orderBy("sequence", "asc")
    );
    const screens = await getDocs(q);
    let dataarr = [];
    screens.forEach((doc) => {
      let obj = doc.data();
      obj = { ...obj, id: doc.id };
      dataarr.push(obj);
    });
    console.log(dataarr);
    return dataarr;
  }

  const addQuestion = async (e) => {
    e.preventDefault();
    try {
      setLoading("pending");
      const data = await addUrls();
      console.log("completed");
      console.log(data);

      await addDoc(collection(db, "questions"), {
        imgURL: type === "image" ? data : null,
        eventID: localStorage.getItem("eventID"),
        options: {
          ["1"]: option1,
          ["2"]: option2,
          ["3"]: option3,
          ["4"]: option4,
        },
        question: question,
        timer: parseInt(timer),
        sequence: questions.length + 1,
        timestamp: serverTimestamp(),
        videoURL: type === "video" ? data : null,
      });
      console.log("image url ", imageUrls);
      console.log("video url ", videoUrls);
      console.log("added");
      history.push("/dragablequestions");
      setLoading(null);
      NotificationManager.success("Question Added Successfully");
    } catch (e) {
      console.log(e);
      NotificationManager.error("Question Not Added");
    }
  };

  const addUrls = async () => {
    if (image !== null) {
      const imageRef = ref(storage, `${image.name + uuidv4()}`);
      const data = await uploadBytes(imageRef, image);
      const downloadUrl = await getDownloadURL(data.ref);
      return downloadUrl;
    } else if (video !== null) {
      const videoRef = ref(storage, `${video.name + uuidv4()}`);
      const videoUpload = await uploadBytes(videoRef, video);
      const videoDownload = await getDownloadURL(videoUpload.ref);
      return videoDownload;
    }
  };

  useEffect(() => {
    console.log(imageUrls);
    console.log(videoUrls);
  }, [imageUrls, videoUrls]);

  return (
    <div>
      <Container className={"px-4 pb-4"}>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add New Questions"
            className="text-sm-left"
          />
        </Row>
        <Card>
          <CardHeader>Add Questions</CardHeader>
          <CardBody>
            <Form onSubmit={addQuestion}>
              <p>Type:</p>
              <Row>
                <Col>
                  <FormRadio
                    name="type"
                    disabled={loading === "pending"}
                    value={type}
                    checked={type === "text"}
                    onChange={() => {
                      setType("text");
                    }}
                  >
                    Text
                  </FormRadio>
                </Col>

                <Col>
                  <FormRadio
                    name="type"
                    value={type}
                    disabled={loading === "pending"}
                    checked={type === "image"}
                    onChange={() => {
                      setType("image");
                    }}
                  >
                    Image
                  </FormRadio>
                </Col>
                <Col>
                  <FormRadio
                    name="type"
                    checked={type === "video"}
                    disabled={loading === "pending"}
                    value={type}
                    onChange={() => {
                      setType("video");
                    }}
                  >
                    Video
                  </FormRadio>
                </Col>
              </Row>
              <FormGroup>
                <label htmlFor="#question">Question</label>
                <FormInput
                  size="lg"
                  maxlength="200"
                  disabled={loading === "pending"}
                  type="text"
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  id="#question"
                  placeholder="Question"
                />
              </FormGroup>

              <p>Options:</p>
              <Row>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option A</label>
                    <FormInput
                      size="lg"
                      maxlength="38"
                      disabled={loading === "pending"}
                      required
                      onChange={(e) => {
                        setOption1(e.target.value);
                      }}
                      value={option1}
                      type="text"
                      placeholder="Option A"
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option B</label>
                    <FormInput
                      size="lg"
                      maxlength="38"
                      disabled={loading === "pending"}
                      required
                      onChange={(e) => {
                        setOption2(e.target.value);
                      }}
                      value={option2}
                      type="text"
                      placeholder="Option B"
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option C</label>
                    <FormInput
                      size="lg"
                      maxlength="38"
                      disabled={loading === "pending"}
                      required
                      onChange={(e) => {
                        setOption3(e.target.value);
                      }}
                      value={option3}
                      type="text"
                      placeholder="Option C"
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option D</label>
                    <FormInput
                      size="lg"
                      maxlength="38"
                      disabled={loading === "pending"}
                      required
                      onChange={(e) => {
                        setOption4(e.target.value);
                      }}
                      value={option4}
                      type="text"
                      placeholder="Option D"
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <label htmlFor="#options">Timer</label>
                    <FormInput
                      style={{ width: "290px" }}
                      disabled={loading === "pending"}
                      size="lg"
                      min="0"
                      max="3600"
                      required
                      type="number"
                      value={timer}
                      onChange={(e) => {
                        setTimer(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                {type === "image" && (
                  <Col lg={4}>
                    <FormGroup>
                      <label htmlFor="#options">Image</label>
                      <FormInput
                        size="lg"
                        disabled={loading === "pending"}
                        type="file"
                        required={type === "image"}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                {type === "video" && (
                  <Col lg={4}>
                    <FormGroup>
                      <label htmlFor="#options">Video</label>
                      <FormInput
                        size="lg"
                        disabled={loading === "pending"}
                        type="file"
                        required={type === "video"}
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={(e) => {
                          setVideo(e.target.files[0]);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  className=""
                  disabled={loading === "pending"}
                  type="submit"
                >
                  {loading === "pending" ? (
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Add"
                  )}
                </Button>
              </div>
            </Form>
            {/* <Link to={"/dragablequestions"}>
              <Button className="btn-secondary" style={{ marginRight: "10px" }}>
                Cancel
              </Button>
            </Link> */}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
