import React, { useEffect, useState } from "react";
import {
  doc,
  increment,
  orderBy,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "../firebase";

const ActivateQuestion = () => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getScreens();
      console.log(data);
      setQuestions(data);
    };
    fetch();
  }, []);

  const getScreens = async () => {
    const questionsRef = collection(db, "questions");
    const q = query(questionsRef, orderBy("timestamp"));
    const screens = await getDocs(q);
    let dataarr = [];
    screens.forEach((doc) => {
      let obj = doc.data();
      obj = { ...obj, id: doc.id };
      dataarr.push(obj);
    });
    return dataarr;
  };

  const callTimer = () => {
    let arrayIndex = 0;
    updatedata(arrayIndex);
  };
  
  const startTimer = async (arrayIndex) => {
    const docRef = doc(db, "events", "LQbiKLiCjov1ysvyelse");
    const docSnap = await getDoc(docRef);
    // for (let i = 0; i < docSnap.data().timer; i++) {
    let i = 0;
    const interval = setInterval(() => {
      updateDoc(docRef, {
        timer: increment(-1),
      });
      console.log(i, docSnap.data().timer);
    if (i == docSnap.data().timer-1) {
        // console.log("timer", docSnap.data().timer);
        // console.log("i", i);
        clearInterval(interval);
        console.log(questions.length, arrayIndex);
        if (questions.length !== arrayIndex + 1) {
          arrayIndex++;
          updatedata(arrayIndex);
        }
      }
      i++;
    }, 1000);
  };

  const updatedata = async (e) => {
    console.log(e);
    const docRef = doc(db, "events", "LQbiKLiCjov1ysvyelse");
    await updateDoc(docRef, {
      timer: questions[e].timer,
      currentQuestion: questions[e].id,
      didEventExpire: questions.length === e + 1 ? true : false,
    });
    // if(questions.length!=e+1){
    startTimer(e);
    // }
  };

  return (
    <div>
      <button className={"btn btn-primary"} onClick={callTimer}>
        done
      </button>
    </div>
  );
};

export default ActivateQuestion;
