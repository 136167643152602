// import { LoremIpsum } from "lorem-ipsum";
// import { generateFromString } from "generate-avatar";

import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { NotificationManager } from "react-notifications";
import { db } from "../firebase";

const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const CardHeader = styled.div`
  font-weight: 500;
  text-align: start;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;

const CardFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DragItem = styled.div`
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
`;

// const lorem = new LoremIpsum();
const updateSequence = async () => {
  const q = query(collection(db, "questions"));
  const querySnapshot = await getDocs(q);
  querySnapshot.docs.map((element) => console.log(element));
};

const ListItem = ({ item, provided, snapshot, index, deleteQuestion }) => {
  const randomHeader = useMemo(() => "1212", []);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(null);
  useEffect(() => {
    console.log(modal);
  }, [modal]);

  const deleted = async (id) => {
    setLoader(null);
    deleteQuestion(id);
    setLoader("pending");
  };

  const Rules = () => {
    setModal(!modal);
  };

  return (
    <>
      <DragItem
        ref={provided.innerRef}
        snapshot={snapshot}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >

        <Row>
          <Col md={10}>
          <span>{index + 1+"-"}</span>  <span className="mb-0" style={{wordBreak:"break-all"}}>{item.question}</span>
          </Col>
          <Col md={2} className="d-flex justify-content-end">
            <p style={{ color: "green" }}>
              {item.imgURL !== null
                ? "Image"
                : item.videoURL !== null
                ? "Video"
                : item.videoURL === null && item.imgURL === null
                ? "Text"
                : ""}
            </p>
          </Col>

          <Col className="mt-1" md={2}>
            <p >{"A"+"- " + item.options[1]}</p>
          </Col>
          <Col md={2} className="mt-1">
            <p> {"B"+"- "+ item.options[2]}</p>
          </Col>
          <Col md={2} className="mt-1">
            <p>{"C"+"- " + item.options[3]}</p>
          </Col>
          <Col md={2} className="mt-1">
            <p>{"D"+"- " + item.options[4]}</p>
          </Col>

          <Col lg={4} className="text-end">
            <Link to={`/editQuestions/${item.id}`}>
              <Button className=" btn btn-warning btn-sm" type="submit">
                <i className="fas fa-pencil-alt" />
              </Button>
            </Link>
            <Button
              style={{}}
              className=" m-1 text-center btn btn-danger btn-sm"
              onClick={Rules}
            >
              <i className="fas fa-trash" />
            </Button>
          </Col>
        </Row>
      </DragItem>

      <Modal open={modal} toggle={Rules}>
        <ModalHeader>Delete</ModalHeader>
        <ModalBody>
          <div className="info text-center">
            Are You Sure You Want To Delete?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-secondary" onClick={Rules}>
            Cancel
          </Button>
          <Button
            disabled={loader === "pending"}
            className="btn-danger"
            onClick={() => deleted(item.id)}
          >
            {loader === "pending" ? (
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              "Delete"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ListItem;
