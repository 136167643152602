import React from "react";
import { Nav } from "shards-react";
import {updateDoc,doc,serverTimestamp} from "firebase/firestore"
import { db } from "../../../firebase";
import SidebarNavItem from "./SidebarNavItem";
import { NotificationManager } from "react-notifications";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  updateEvent() {
    const washingtonRef = doc(db, "events", localStorage.getItem("eventID"));
    updateDoc(washingtonRef, {
      currentQuestion: "",
      didEventExpire: true,
      timer: "",
      timestamp: serverTimestamp(),
    }).then(()=>{
      console.log("successfully updated")
      NotificationManager.info("Event Ended Successfully")
    }).catch(()=>{
      console.log("Error occured")
      NotificationManager.info("Error Occured")
    })
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <>
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
          <div style={{marginLeft:"30px"}}>
           <button style={{width:"100px"}} onClick={this.updateEvent} className="btn btn-danger mt-5">Event End</button>
           </div>
        </Nav>
       
        {/* //style={{marginTop:"550px",marginLeft:"35px"}} */}
        <div style={{fontSize:"12px",textAlign:"center",marginBottom:"15px"}} className="fixed-bottom"  >Copyright © 2022 Quiz-Web-App</div>
      </div>
    
      </>
    )
  }
}

export default SidebarNavItems;
