import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardHeader,
  CardBody,
  FormRadio,
  FormCheckbox,
  FormInput,
  FormGroup,
  Button,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { db, storage } from "../firebase";
import { useParams } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { NotificationManager } from "react-notifications";

import {
  collection,
  query,
  where,
  doc,
  setDoc,
  updateDoc,
  orderBy,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { async } from "@firebase/util";
import { useHistory, Link } from "react-router-dom";

export default function EditQuestions() {
  const { id } = useParams();
  const [imageUrls, setImageUrls] = useState("");
  const [videoUrls, setVideoUrls] = useState("");
  const [type, setType] = useState("text");
  const [question, setQuestion] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [timer, setTimer] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(null);
  const [viewImage,setViewImage]=useState("");
  const [viewVideo,setViewVideo]=useState("");
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      const data = await getScreens();
      console.log(data);
      const quesdata = data.find((e) => {
        return e.id === id;
      });
      setQuestion(quesdata.question);
      setOption1(quesdata.options["1"]);
      setOption2(quesdata.options["2"]);
      setOption3(quesdata.options["3"]);
      setOption4(quesdata.options["4"]);
      setTimer(quesdata.timer);

      // setViewImage(quesdata.imgURL);
     

      if (quesdata.imgURL !== null) {
        setType("image");
        setViewImage(quesdata.imgURL);
      } else if (quesdata.videoURL !== null) {
        setType("video");
        setViewVideo(quesdata.videoURL);
      }
    };
    fetch();
  }, []);
  useEffect(()=>{
console.log(image)
  },[image])

  const getScreens = async () => {
    const questionsRef = collection(db, "questions");
    const q = query(
      questionsRef,
      where("eventID", "==", localStorage.getItem("eventID")),
      orderBy("sequence", "asc")
    );
    console.log(q);
    const screens = await getDocs(q);
    let dataarr = [];
    screens.forEach((doc) => {
      let obj = doc.data();
      obj = { ...obj, id: doc.id };
      dataarr.push(obj);
    });
    return dataarr;
  };

  const addQuestion = async (e) => {
    e.preventDefault();
    try {
      setLoading("pending");
      const data = await addUrls();
      console.log("completed");
      const docREf = doc(db, "questions", id);
      if (type === "video" || type === "image") {
        await updateDoc(docREf, {
          imgURL: type === "image"  ? data : null,
          options: {
            ["1"]: option1,
            ["2"]: option2,
            ["3"]: option3,
            ["4"]: option4,
          },
          question: question,
          timer: timer,
          timestamp: serverTimestamp(),
          videoURL: type === "video" ? data : null,
        });
      } else {
        await updateDoc(docREf, {    
          imgURL:  null,   
          options: {
            ["1"]: option1,
            ["2"]: option2,
            ["3"]: option3,
            ["4"]: option4,
          },
          videoURL:  null,
          question: question,
          timer: timer,
          timestamp: serverTimestamp(),
        });
      }
      console.log("image url ", imageUrls);
      console.log("video url ", videoUrls);
      console.log("added");
      NotificationManager.success("Question Updated Successfully");
      history.push("/dragablequestions");
      setLoading(null);
    } catch (e) {
      console.log(e);
      NotificationManager.error("Question Not Updated");
    }
  };

  const addUrls = async () => {
    if (image !== null) {
      const imageRef = ref(storage, `${image.name + uuidv4()}`);
      const data = await uploadBytes(imageRef, image);
      const downloadUrl = await getDownloadURL(data.ref);
      return downloadUrl;
    } else if (video !== null) {
      const videoRef = ref(storage, `${video.name + uuidv4()}`);
      const videoUpload = await uploadBytes(videoRef, video);
      const videoDownload = await getDownloadURL(videoUpload.ref);
      return videoDownload;
    }
  }

  useEffect(() => {
    console.log(imageUrls);
    console.log(videoUrls);
  }, [imageUrls, videoUrls]);

  return (
    <div style={{ paddingLeft: "2px" }}>
      <Container>
        <Row noGutters className="page-header py-4">
          <PageTitle sm="2" title="Edit Question" className="text-sm-left" />
        </Row>
        <Card>
          <CardHeader>Edit Questions</CardHeader>
          <CardBody>
            <Form onSubmit={addQuestion}>
              <p>Type:</p>
              <Row>
                <Col>
                  <FormRadio
                    name="type"
                    value={type}
                    disabled={loading === "pending"}
                    checked={type === "text"}
                    onChange={() => {
                      setType("text");
                    }}
                  >
                    Text
                  </FormRadio>
                </Col>

                <Col>
                  <FormRadio
                    name="type"
                    value={type}
                    disabled={loading === "pending"}
                    checked={type === "image"}
                    onChange={() => {
                      setType("image");
                    }}
                  >
                    Image
                  </FormRadio>
                </Col>
                <Col>
                  <FormRadio
                    name="type"
                    checked={type === "video"}
                    disabled={loading === "pending"}
                    value={type}
                    onChange={() => {
                      setType("video");
                    }}
                  >
                    Video
                  </FormRadio>
                </Col>
              </Row>
              <FormGroup>
                <label htmlFor="#question">Question</label>
                <FormInput
                  size="lg"
                  maxlength="130"
                  disabled={loading === "pending"}
                  type="text"
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  id="#question"
                  placeholder="Question"
                />
              </FormGroup>
              <p>Options:</p>
              <Row>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option A</label>
                    <FormInput
                      size="lg"
                      disabled={loading === "pending"}
                      required
                      maxlength="38"
                      onChange={(e) => {
                        setOption1(e.target.value);
                      }}
                      value={option1}
                      type="text"
                      placeholder="Option A"
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option B</label>
                    <FormInput
                      size="lg"
                      required
                      disabled={loading === "pending"}
                      maxlength="38"
                      onChange={(e) => {
                        setOption2(e.target.value);
                      }}
                      value={option2}
                      type="text"
                      placeholder="Option B"
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option C</label>
                    <FormInput
                      size="lg"
                      maxlength="38"
                      disabled={loading === "pending"}
                      required
                      onChange={(e) => {
                        setOption3(e.target.value);
                      }}
                      value={option3}
                      type="text"
                      placeholder="Option C"
                    />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup>
                    <label htmlFor="#options">Option D</label>
                    <FormInput
                      size="lg"
                      maxlength="38"
                      disabled={loading === "pending"}
                      required
                      onChange={(e) => {
                        setOption4(e.target.value);
                      }}
                      value={option4}
                      type="text"
                      placeholder="Option D"
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <label htmlFor="#options">Timer</label>
                    <FormInput
                      size="lg"
                      style={{ width: "290px" }}
                      min="1"
                      max="3600"
                      required
                      disabled={loading === "pending"}
                      type="number"
                      value={timer}
                      onChange={(e) => {
                        setTimer(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                {type === "image" && (
                  <Col  lg={4}>
                    <FormGroup>
                      <label htmlFor="#options">Image</label>

                      <FormInput
                        size="lg"
                        type="file"
                        name="upload file"
                        // style={{display :"none"}}
                        disabled={loading === "pending"}
                        required={type === "image"}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                        //  const data =addUrls()
                        //   setImageUrls(data)
                          setImage(e.target.files[0]);
                          setViewImage(URL.createObjectURL(e.target.files[0]))
                        }}
                      />
                      {/* <img  width={100} src={viewImage} alt={"please select image"}/> */}
                    </FormGroup>
                  </Col>
                )}
                {type === "video" && (
                  <Col lg={4}>
                    <FormGroup>
                      <label htmlFor="#options">Video</label>
                      <FormInput
                        size="lg"
                        type="file"
                        disabled={loading === "pending"}
                        required={type === "video"}
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={(e) => {
                          setVideo(e.target.files[0]);
                          setViewVideo(URL.createObjectURL(e.target.files[0]))
                        }}
                      />
                        {/* <video
                        src={viewVideo}
                        width="200"
                        // height="300"
                        type="video/mp4"
                      ></video> */}
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <div className="d-flex justify-content-end">
                {/* <Button >Cancel</Button> */}
                <Button
                  className=""
                  disabled={loading === "pending"}
                  type="submit"
                >
                  {loading === "pending" ? (
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
