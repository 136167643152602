// import "./styles.css";
import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  FormInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import { NotificationManager } from "react-notifications";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "";
import {} from "shards-react";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ListItem from "./listItem";

const elements = [
  { id: "one", content: "one" },
  { id: "two", content: "two" },
  { id: "three", content: "three" },
  { id: "four", content: "four" },
];

function DragableQuestions() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      setLoading("pending");
      const data = await tempFunc();
      setQuestions(data);
      setLoading(null);
    };
    fetch();
  }, []);

  useEffect(() => {
    console.log(questions);
  }, [questions]);

  async function tempFunc() {
    const questionsRef = collection(db, "questions");
    const q = query(
      questionsRef,
      where("eventID", "==", localStorage.getItem("eventID")),
      orderBy("sequence", "asc")
    );
    const screens = await getDocs(q);
    let dataarr = [];
    screens.forEach((doc) => {
      let obj = doc.data();
      obj = { ...obj, id: doc.id };
      dataarr.push(obj);
    });
    console.log(dataarr);
    return dataarr;
  }

  const onDragEnd = async (result) => {
    console.log(result);
    const newItems = Array.from(questions);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    console.log([removed]);
    setQuestions(newItems);
    console.log(
      newItems,
      result.destination.index + 1,
      result.source.index + 1
    );
  };
  const updateSequence = async () => {
    let increment = 0;
    // questions.map((element) => {
    //   let q = query(doc(db, "questions", element.id));
    //   updateDoc(q, {
    //     sequence: increment + 1,
    //   });
    //   increment++;
    // });
    try {
      setLoader("pending");
      for (const prop of questions) {
        let q = query(doc(db, "questions", prop.id));
        await updateDoc(q, {
          sequence: increment + 1,
        });
        increment++;
      }
      setLoader(null);
      NotificationManager.info("Questions sequence is updated");
    } catch (e) {
      NotificationManager.error("Something went wrong");
    }
  };

  const deleteQuestion = async (id) => {
    try {
      console.log(id);
      await deleteDoc(doc(db, "questions", id));
      const deleteQuestion = questions.filter((e) => {
        return e.id !== id;
      });
      setQuestions(deleteQuestion);
      NotificationManager.success("Question Deleted Successfully");
    } catch (error) {
      console.log(error);
      NotificationManager.error("Something Went Wrong");
    }
  };

  return (
    <div>
      <Container className={"px-3 pb-4 mt-3"}>
        <h3
          style={{
            fontWeight: "500",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
          }}
        >
          Questions
        </h3>

        <Card style={{backgroundColor: "#dae0eb" }}>
          <CardHeader className="d-flex justify-content-between">
            <div className="text-center">Questions</div>
            <div>
              <Link to={"/AddQuestions"}>
                <Button>Add Question</Button>
              </Link>
            </div>
          </CardHeader>
          <Container className=" pt-5 pb-5" style={{ width: "100%" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questions.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                            <ListItem
                            
                                provided={provided}
                                snapshot={snapshot}
                                item={item}
                                index={index}
                                deleteQuestion={deleteQuestion}
                              />
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Container>
        </Card>
      </Container>
      <div className="d-flex justify-content-center mb-3">
        <button
          className="btn btn-primary"
          disabled={loader === "pending"}
          onClick={updateSequence}
        >
          {loader === "pending" ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            "Update sequence"
          )}
        </button>
      </div>
    </div>
  );
}
export default DragableQuestions;
