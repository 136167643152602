export default function() {
  return[
    // {
    //   title: "View Questions",
    //   htmlBefore: '<i class="fas fa-eye text-primary" ></i>',
    //   to: "/viewquestions",
    // },
    // {
    //   title: "Blog Dashboard",
    //   to: "/blog-overview",
    //   htmlBefore: '<i class="material-icons">edit</i>',
    //   htmlAfter: "",
    // },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    {
      title: "User Avatar Details",
      htmlBefore: '<i  class="fas fa-info-circle  text-success "  > </i>',
      to: "/avatarInfo",

    },
    {
      title: "Users",
      htmlBefore: '<i class="fas fa-users text-dark"></i>',
      to: "/usersInfo",
    },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // },
    
    // {
    //   title: "Edit Questions",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/editQuestions",
    // },
    
    // {
    //   title: "Add Questions",
    //   htmlBefore: '<i class="material-icons">edit</i>',
    //   to: "/AddQuestions",
    // },
    
    {
      title: "Questions",
      htmlBefore: '<i class="fas fa-question-circle text-secondary"></i>',
      to: `/dragablequestions`,
    }
    // {
    //   title:"Question Report",
    //   htmlBefore: '<i class="material-icons">report</i>',
    //   to: "/viewquestions",
    // }
  ];
}
